import { generatePath, useParams } from "react-router-dom";
import Api from "../../../../../../../../api/Api";
import { CategoryRootDto } from "../../../../../../../../api/category/category.dto";
import { useQuery } from "../../../../../../../../api/useQuerySlice";
import Col from "../../../../../../../../libs/grid/col";
import PanelGrid from "../../../../../../../../libs/grid/panel-grid";
import PanelRow from "../../../../../../../../libs/grid/panel-row";
import { RoutePath } from "../../../../../../../../route-paths";
import BreadcrumbsItem from "../../../../../../../components/Breadcrumbs/BreadcrumbsItem";
import Tile from "../../../../../../../components/Tile/Tile";
import CategoryEditForm from "./forms/CategoryEditForm";
import BreakTitle from "../../../../../../../components/BreakTitle/BreakTitle";
import { LangDto } from "../../../../../../../../api/lang/lang.dto";
import CategoryLangEditForm from "./forms/CategoryLangEditForm";
import LangSwitch from "../../../../../../../components/LangSwitch/LangSwitch";
import CategoryLangNonExistTile from "./forms/CategoryLangNonExistTile";
import MediaList from "./media-list/media-list";
import FaqListWrapper from "./faq-list/faq-list-wrapper";
import RelatedArticles from "./related-articles/related-articles-list";
import RelatedCategoriesSeeAlso from "./related-categories-see-aslo/related-categories-list";

const CategoryEditPage = () => {
  const { id = "" } = useParams<{ id: string }>();
  const { data: categoryRoot } = useQuery<CategoryRootDto, number>("category/getRoot", [Api.Category.GetRoot, Number(id)], {
    statusResetDelay: 1000,
  });

  return (
    <div>
      <BreadcrumbsItem to={RoutePath.PANEL_ASSORTMENT_CATEGORIES_LIST} title="Lista" />
      <BreadcrumbsItem to={generatePath(RoutePath.PANEL_ASSORTMENT_CATEGORIES_EDIT, { id: id })} title="Edit category" />
      <PanelGrid>
        <PanelRow>
          <Col size={12}>
            <Tile>
              <h1>Edit category</h1>
            </Tile>
          </Col>
        </PanelRow>
        <PanelRow>
          <Col size={12}>
            <BreakTitle size={"xl"}>Shared settings (no lang)</BreakTitle>
          </Col>
          <Col size={[6, 8, 10, 12]}>
            <Tile>
              <h2 className="title">Base informations</h2>
              <CategoryEditForm data={categoryRoot} />
            </Tile>
          </Col>
          <Col size={[6, 8, 10, 12]} vif={!!categoryRoot && !!categoryRoot?.id}>
            <Tile>
              <RelatedArticles categoryRootId={categoryRoot?.id ?? -1} />
            </Tile>
            <br />
            <Tile>
              <RelatedCategoriesSeeAlso categoryRootId={categoryRoot?.id ?? -1} />
            </Tile>
          </Col>
        </PanelRow>
        <LangSwitch
          render={({ langs, activeLang }) =>
            categoryRoot && activeLang
              ? langs?.map((lang: LangDto, index: number) => {
                const categoryLangIndex = categoryRoot?.categoryLangs?.findIndex((x) => x.langId === lang.id);
                const rootContainsLang = categoryLangIndex !== -1;
                if (!rootContainsLang) return <CategoryLangNonExistTile lang={lang} key={index} />;
                const categoryLang = categoryRoot.categoryLangs[categoryLangIndex];
                return rootContainsLang ? (
                  <PanelRow className={activeLang.id === lang.id ? "" : "d-none"} key={index}>
                    <Col size={[6, 6, 10, 12]}>
                      <Tile>
                        <h2 className="title">Base informations</h2>
                        <CategoryLangEditForm selectedLang={lang} />
                      </Tile>
                    </Col>
                    <Col size={[6, 6, 12, 12]}>
                      <Tile>{categoryLang.id ? <MediaList scopeId={categoryLang.id!} /> : null}</Tile>
                      <br />
                      <Tile>
                        <FaqListWrapper data={categoryLang} />
                      </Tile>
                    </Col>
                  </PanelRow>
                ) : (
                  <CategoryLangNonExistTile lang={lang} key={index} />
                );
              })
              : null
          }
        />
      </PanelGrid>
    </div>
  );
};

export default CategoryEditPage;
