import React, { useContext, useEffect, useState } from "react";
import Api from "../../../../../../../../../api/Api";
import { CategoryRootDto, SeeAlsoAddRelationDto } from "../../../../../../../../../api/category/category.dto";
import { useQuery } from "../../../../../../../../../api/useQuerySlice";
import LoadingBtn from "../../../../../../../../../libs/buttons/Button/LoadingBtn";
import GenericList from "../../../../../../../../../libs/generic-list/generic-list";
import { ModalContext } from "../../../../../../../../components/Modal/ModalContext";
import "./../related-add-modal.scss";
import { IdNameDto } from "../../../../../../../../../api/tools/tools.models.dto";

const RelatedCategorySeeAlsoAddEditModal = (props: { categoryRootId: number }) => {
  const { categoryRootId } = props;
  const { handleConfirm } = useContext(ModalContext);
  const [selectedItem, setSelectedItem] = useState<CategoryRootDto>();

  const { data, call: callList, status: listStatus } = useQuery<Array<IdNameDto>>("Category/ListRootIdNames", [Api.Category.ListRootIdNames]);
  const { call, status } = useQuery<void, SeeAlsoAddRelationDto>(
    "Category/AddSeeAlsoCategoryRelation",
    [Api.Category.AddSeeAlsoCategoryRelation],
    {
      callOnLoad: false,
    }
  );

  useEffect(() => {
    callList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onSubmit = () => {
    if (selectedItem && selectedItem?.id) {
      var addDto = {
        categoryRootId: categoryRootId,
        relatedCategoryRootId: selectedItem.id,
      } as SeeAlsoAddRelationDto;
      call(addDto);
    }
  };

  useEffect(() => {
    if (status === "finished") handleConfirm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [status]);

  return (
    <div className="related-add-modal">
      {data ? (
        <>
          <div className="list-wrapper">
            <GenericList
              status={listStatus}
              data={data}
              cols={[
                ["Id", "id", 1],
                ["Name", "name", 10],
              ]}
              inputProps={{
                multiselect: false,
                onChange: (x: any) => setSelectedItem(x),
              }}
            />
          </div>
        </>
      ) : null}

      <div className="ml-auto pt-3">
        <LoadingBtn onClick={() => onSubmit()} status={status} className="ml-auto">
          Add
        </LoadingBtn>
      </div>
    </div>
  );
};

export default RelatedCategorySeeAlsoAddEditModal;
